import MmenuLight from 'mmenu-light'
import bodymovin from "lottie-web"

import setupHomeBannerVideo from './home-banner-video.js'

import { gsap } from "../gsap/gsap.min";
import { ScrollTrigger } from "../gsap/ScrollTrigger.min";
import { DrawSVGPlugin } from "../gsap/DrawSVGPlugin.min";


gsap.registerPlugin(ScrollTrigger);

setupHomeBannerVideo()

document.addEventListener('DOMContentLoaded', () => {

    const menu = new MmenuLight(
        document.querySelector("#mobile-menu"),
        "(max-width: 1280px)"
    );
    
    const navigator = menu.navigation();
    const drawer = menu.offcanvas();

    // Open Menu
    const navTrigger = document.querySelector(".js-open-nav");
    const projectWrapper = document.getElementById("app");

    // Toggle Menu
    bindEvent(navTrigger, "click", () => {
        navTrigger.classList.toggle("is-active");
        projectWrapper.classList.toggle("js-is-open");

        if (navTrigger.classList.contains("is-active")) {
          drawer.open();
        } else {
          drawer.close();
        }
    });


    // Accordions
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach((accordion) => {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(event) {
        const link = this.closest('[data-accordion-link]');
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach((content) => {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
        });
    }

    // Bind event
    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener) {
          el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent) {
          el.attachEvent(`on${  eventName}`, eventHandler);
        }
    }


    const lottieHomeIntro = document.getElementById("home-intro-lottie");

    if (lottieHomeIntro) {
        const animationA = bodymovin.loadAnimation({
          container: lottieHomeIntro,
          path: "/lotties/Half_Circle.json",
          renderer: "svg",
          loop: false,
          autoplay: false,
        });

        ScrollTrigger.create({
          trigger: ".lottie-start",
          //start: "top 40%",
          onEnter: playThis,
        });

        function playThis() {
            animationA.play();
        }

        let tlFadeIn = gsap.timeline({ 
            scrollTrigger: {
                trigger: '.lottie-start',
                //start: "top 40%",
            }
        }); 

        tlFadeIn.fromTo(".fade-in", {opacity:0, x:-40}, {opacity:1, x:0, duration: .5, delay: 1.5})
        
    }

    const homeImg = document.querySelector(".home .fade-in-img-content");

    var sections = gsap.utils.toArray(".home .section-image-content");

    sections.forEach((elem, i) => {
        
        var trigger = elem.querySelector(".fade-in-img-content");
        var headlines = elem.querySelector(".fade-in-img-content");

        ScrollTrigger.matchMedia({
            "(max-width: 1279px)": function() {
            
            let tlFadeInHome = gsap.timeline({ 
                scrollTrigger: {
                    trigger: trigger,
                    start: "top 80%",
                }
            }); 

            tlFadeInHome.fromTo(headlines, {opacity:0, x:-40}, {opacity:1, x:0, duration: .5})
        
        },"(min-width: 1280px)": function() {

            let tlFadeInHome = gsap.timeline({ 
                scrollTrigger: {
                    trigger: trigger,
                    start: "top 40%",
                }
            }); 

            tlFadeInHome.fromTo(headlines, {opacity:0, x:-40}, {opacity:1, x:0, duration: .5})

        }});
        
    });

    const lottieInternalIntro = document.getElementById("internal-intro-lottie");

    if (lottieInternalIntro) {
        const animationB = bodymovin.loadAnimation({
          container: lottieInternalIntro,
          path: "/lotties/Circle_1_v2.json",
          renderer: "svg",
          loop: false,
          autoplay: false,
        });

        ScrollTrigger.create({
          trigger: ".lottie-start",
          start: "top 40%",
          scrub: 1,
          onEnter: playThisB,
        });

        function playThisB() {
            animationB.play();
        }
         
    }

    const lottieCapabilities = document.getElementById("capbilities-lottie");

    if (lottieCapabilities) {
        const animationC = bodymovin.loadAnimation({
          container: lottieCapabilities,
          path: "/lotties/Circle_2.json",
          renderer: "svg",
          loop: false,
          autoplay: false,
        });

        ScrollTrigger.create({
          trigger: ".section-capabilities",
          start: "top 40%",
          scrub: 1,
          onEnter: playThisC,
        });

        function playThisC() {
            animationC.play();
        }
    }

    const timeLineA = document.querySelector(".section-home-intro");
    const timeLineB = document.querySelector(".section-content");
    const timeLineC = document.querySelector(".section-capabilities");
    const timeLineD = document.querySelector(".section-cta");
    const timeLineE = document.querySelector(".section-banner__lower");


    if (timeLineA) {
        ScrollTrigger.matchMedia({
            "(max-width: 1279px)": function() {
                let tlA = gsap.timeline({ 
                    scrollTrigger: {
                        trigger: '.section-home-intro',
                        start: "top 80%",
                    }
                }); 

                tlA.to("#circleStart", {opacity:1, duration: .15})
                tlA.set("#circleStart", {className: '-=circle circle-start'})
                tlA.from(".st0", {drawSVG: 0, duration: 1}, 0)
                tlA.to("#circleEnd", {opacity: 1, duration: .15})
                tlA.fromTo(".fade-in-img", {opacity:0, x:-40}, {opacity:1, x:0, duration: .5})
            },
            "(min-width: 1280px)": function() { 
                let tlA = gsap.timeline({ 
                    scrollTrigger: {
                        trigger: '.section-home-intro',
                        start: "top 40%",
                    }
                }); 

                tlA.to("#circleStart", {opacity:1, duration: .15})
                tlA.set("#circleStart", {className: '-=circle circle-start'})
                tlA.from(".st0", {drawSVG: 0, duration: 1}, 0)
                tlA.to("#circleEnd", {opacity: 1, duration: .15})
                tlA.fromTo(".fade-in-img", {opacity:0, x:-40}, {opacity:1, x:0, duration: .5})

            }});
    }

    if (timeLineB) {
        let tlB = gsap.timeline({ 
            scrollTrigger: {
                trigger: '.section-content',
                start: "top 40%",
            }
        }); 

        tlB.to("#circleStartContent", {opacity:1, duration: .15})
        tlB.set("#circleStartContent", {className: '-=circle circle-start'})
        tlB.from(".st1", {drawSVG: 0, duration: 1}, 0)
        tlB.to("#circleEndContent", {opacity: 1, duration: .15})
    }

    if (timeLineC) {
        let tlC = gsap.timeline({ 
            scrollTrigger: {
                trigger: '.section-capabilities',
                start: "top 40%",
            }
        }); 

        tlC.to("#circleStartCapa", {opacity:1, duration: .15})
        tlC.set("#circleStartCapa", {className: '-=circle circle-start'})
        tlC.from(".st2", {drawSVG: 0, duration: 1}, 0)
        tlC.to("#circleEndCapa", {opacity: 1, duration: .15})
    }

    if (timeLineD) {
        let tlD = gsap.timeline({ 
            scrollTrigger: {
                trigger: '.section-cta',
                start: "top 40%",
            }
        }); 

        tlD.to("#circleStartCta", {opacity:1, duration: .15})
        tlD.set("#circleStartCta", {className: '-=circle circle-start'})
        tlD.from(".st3", {drawSVG: 0, duration: 1}, 0)
        tlD.to("#circleEndCta", {opacity: 1, duration: .15})
    }

    if (timeLineE) {
        let tlE = gsap.timeline({ 
            scrollTrigger: {
                trigger: '.section-banner__lower',
                start: "top 40%",
            }
        }); 

        tlE.to("#circleStartBana", {opacity:1, duration: .15})
        tlE.set("#circleStartBana", {className: '-=circle circle-start'})
        tlE.from(".st4", {drawSVG: 0, duration: 1}, 0)
        tlE.to("#circleEndBana", {opacity: 1, duration: .15})
    }


});
