export default function() {
    const homeBannerVideo = document.querySelector('[home-banner-video]')
    if(homeBannerVideo){
        let options = {
            rootMargin: '0px',
            threshold: 0.5
        }
        let observer = new IntersectionObserver(onHomeBannerVideoIntersection, options)
        observer.observe(homeBannerVideo)
    }

}

function onHomeBannerVideoIntersection(entries, observer) {
    entries.forEach(entry => {
        if (entry.boundingClientRect.top >= 0) return
        entry.target.loop = false
        observer.disconnect()
    })
}